body {
  margin: 0;
  font-family: "Droid Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

.close {
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  position: absolute;
  color: inherit;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  font-weight: 700;
  font-size: 1.5rem;
  float: right;
  opacity: 0.5;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
}
