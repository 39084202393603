.ol-viewport {
  border-radius: 20px;
}

.map-container-page .ol-viewport {
  border-radius: 0px;
}
  
  .ol-scale-line {
    background: rgba(0, 60, 136, 0.3);
    border-radius: 4px;
    bottom: 8px;
    left: 8px;
    padding: 2px;
    position: absolute; 
  }
  
  .ol-scale-line-inner {
    border: 1px solid #eee;
    border-top: none;
    color: #eee;
    font-size: 10px;
    text-align: center;
    margin: 1px;
    will-change: contents, width;
    transition: all 0.25s; 
  }
  
  .ol-scale-bar {
    position: absolute;
    bottom: 8px;
    left: 8px; 
  }
  
  .ol-scale-step-marker {
    width: 1px;
    height: 15px;
    background-color: #000000;
    float: right;
    z-Index: 10; 
  }
  
  .ol-scale-step-text {
    position: absolute;
    bottom: -5px;
    font-size: 12px;
    z-Index: 11;
    color: #000000;
    text-shadow: -2px 0 #FFFFFF, 0 2px #FFFFFF, 2px 0 #FFFFFF, 0 -2px #FFFFFF; 
  }
  
  .ol-scale-text {
    position: absolute;
    font-size: 14px;
    text-align: center;
    bottom: 25px;
    color: #000000;
    text-shadow: -2px 0 #FFFFFF, 0 2px #FFFFFF, 2px 0 #FFFFFF, 0 -2px #FFFFFF; 
  }
  
  .ol-scale-singlebar {
    position: relative;
    height: 10px;
    z-Index: 9;
    box-sizing: border-box;
    border: 1px solid black; 
  }
  
  .ol-unsupported {
    display: none; 
  }
  
  .ol-viewport, .ol-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
  }
  
  .ol-selectable {
    -webkit-touch-callout: default;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text; 
  }
  
  .ol-grabbing {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing; 
  }
  
  .ol-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab; 
  }
  
  .ol-control {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    padding: 2px; 
  }
  
  .ol-control:hover {
    background-color: rgba(255, 255, 255, 0.6); 
  }
  
  .ol-zoom {
    left: unset;
    right: 0.5em; 
    top: 0.5em; 
  }
  
  .ol-rotate {
    top: .5em;
    right: .5em;
    transition: opacity .25s linear, visibility 0s linear; 
  }
  
  .ol-rotate.ol-hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s linear, visibility 0s linear .25s; 
  }
  
  .ol-zoom-extent {
    top: 4.643em;
    left: .5em; 
  }
  
  .ol-full-screen {
    right: .5em;
    top: .5em; 
  }
  
  .ol-control button {
    display: block;
    margin: 1px;
    padding: 0;
    color: white;
    font-size: 1.14em;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    height: 1.375em;
    width: 1.375em;
    line-height: .4em;
    background-color: rgba(0, 60, 136, 0.5);
    border: none;
    border-radius: 2px; 
  }
  
  .ol-control button::-moz-focus-inner {
    border: none;
    padding: 0; 
  }
  
  .ol-zoom-extent button {
    line-height: 1.4em; 
  }
  
  .ol-compass {
    display: block;
    font-weight: normal;
    font-size: 1.2em;
    will-change: transform; 
  }
  
  .ol-touch .ol-control button {
    font-size: 1.5em; 
  }
  
  .ol-touch .ol-zoom-extent {
    top: 5.5em; 
  }

.ol-control button:hover,
.ol-control button:focus,
ol-button-redirect:hover,
.ol-button-redirect:focus {
  text-decoration: none;
  background-color: rgba(0, 60, 136, 0.7);
}

  .ol-button-redirect {
    display: block;
    margin: 1px;
    padding: 3px;
    color: white;
    font-size: 1.14em;
    text-decoration: none;
    text-align: center;
    background-color: rgba(0, 60, 136, 0.4);
    border: 3px rgba(255, 255, 255, 0.4) solid;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .ol-zoom .ol-zoom-in {
    border-radius: 2px 2px 0 0; 
  }
  
  .ol-zoom .ol-zoom-out {
    border-radius: 0 0 2px 2px; 
  }
  
  .ol-attribution {
    text-align: right;
    bottom: .5em;
    right: .5em;
    max-width: calc(100% - 1.3em); 
  }
  
  .ol-attribution ul {
    margin: 0;
    padding: 0 .5em;
    color: #000;
    text-shadow: 0 0 2px #fff; 
  }
  
  .ol-attribution li {
    display: inline;
    list-style: none; 
  }
  
  .ol-attribution li:not(:last-child):after {
    content: " "; 
  }
  
  .ol-attribution img {
    max-height: 2em;
    max-width: inherit;
    vertical-align: middle; 
  }
  
  .ol-attribution ul, .ol-attribution button {
    display: inline-block; 
  }
  
  .ol-attribution.ol-collapsed ul {
    display: none; 
  }
  
  .ol-attribution:not(.ol-collapsed) {
    background: rgba(255, 255, 255, 0.8); 
  }
  
  .ol-attribution.ol-uncollapsible {
    bottom: 0;
    right: 0;
    border-radius: 4px 0 0; 
  }
  
  .ol-attribution.ol-uncollapsible img {
    margin-top: -.2em;
    max-height: 1.6em; 
  }
  
  .ol-attribution.ol-uncollapsible button {
    display: none; 
  }


.popover {
  min-width: 300px !important;
}

.popover-header {
  background-color: #344955;
}

.popover-body {
  background-color: #4b6572;
  color: #ffffff;
}

.sidebar {
  position: absolute;
  left: -20vw;
  width: 20vw;
  transition: left 0.3s ease-in-out;
  background-color: #4a6572;
  z-index: 1;
  padding: 0px;
}

.sidebar-toggle {
  position: absolute;
  top: 20px;
  right: -35px;
  height: 40px;
  width: 35px;
  z-index: 1;
  background-color: #4a6572;
  border: 0px;
  border-radius: 0px 10px 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.sidebar-button-container {
  position: absolute;
  top: 80px;
  right: -50px;
  height: 40px;
  width: 40px;
  z-index: 1;
  background-color: transparent;
  border: 0px;
  border-radius: 40px;
}

.sidebar-button {
  height: 40px;
  width: 40px;
  z-index: 1;
  background-color: #4a6572;
  border: 0px;
  border-radius: 40px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.sidebar.open {
  left: 0;
}

.btn-carto-parcelle-action-container {
  padding: 0px;
}

.btn-carto-parcelle-action {
  width: 100%;
  border-radius: 0px;
  border: 0.5px solid;
  background-color: #f19f28;
}

.btn-carto-parcelle-action:hover {
  color: #000000 !important;
}

.carto-attribution a {
  color: #ffffff;
}

.section-accordion-carto {
  padding: 0px;
}

.accordion-item {
  background-color: #4a6572 !important;
  color: #ffffff !important;
;}

.accordion-button {
  background-color: #415761 !important;
  color: #ffffff !important;
  display: flow;
  text-align: center;
}

.subsection-header {
  background-color: #4a616c !important;
  color: #ffffff !important;
  display: flow;
  text-align: center;
}