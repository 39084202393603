.loader-wrapper {
  width: 60px;
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.loader {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 5px solid transparent;
  border-top-color: #4bc8eb;
  border-bottom-color: #f9aa33;
  border-radius: 50%;
  animation: rotate 5s linear infinite;
}

.loader-inner {
  border-top-color: #4bc8eb;
  border-bottom-color: #f9aa33;
  animation-duration: 2.5s;
}

@keyframes rotate {
  0% {
    transform: scale(1) rotate(360deg);
  }
  50% {
    transform: scale(0.8) rotate(-360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}
